import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/Layout"
import styles from "../styles/modules/post.module.sass"
import Tag from "../components/Tag"
// import classNames from "classnames"
// import Image from "gatsby-image"

export default ({
  data: {
    markdownRemark: { html, frontmatter },
  },
}) => {
  return (
    <Layout frontmatter={frontmatter}>
      <article className="slipnote-section">
        <div className="slipnote-section slipnote-padding-remove-top slipnote-relative slipnote-divider-bottom">
          <span className={styles.date}>
            <span uk-icon="icon: calendar"></span> {frontmatter.date}
          </span>
          <h1 className={styles.title}>{frontmatter.title}</h1>
          <div className={styles.tags}>
            {frontmatter.tags.length &&
              frontmatter.tags.map(tag => <Tag key={tag} tag={tag} />)}
          </div>
        </div>
        <div
          className="slipnote-section"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
        description
        date(formatString: "YYYY年MM月DD日")
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 820) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      tableOfContents(maxDepth: 3)
    }
  }
`
